<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <UAMTable />
            </v-col>
        </v-row>
        <!-- <v-row>
            <v-col>
                <ItemIssuerList/>
            </v-col>
        </v-row> -->
        <v-row v-if="$store.state.user && $store.getters.getAccessByLevels(['uam'])">
          <v-col cols="12">
              <RootTable />
          </v-col>
      </v-row>
    </v-container>
</template>
<script>
import UAMTable from '@/components/lists/UAMTable'
// import ItemIssuerList from '@/components/lists/accounts/ItemIssuerList'
import RootTable from '@/components/lists/RootTable'
export default {
    name:'UAMPage',
    components:{
        UAMTable,
        RootTable,
        // ItemIssuerList
    }
}
</script>