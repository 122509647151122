<template>
    <v-dialog v-model="dialog" max-width="1000">
      <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :background-color="$store.getters.getColorPalette().background1ColorCode" :color="$store.getters.getColorPalette().accentCode" :dark="$store.getters.getColorPalette().isDark" outlined small text
      >
        Create UAM User<v-icon :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-account-plus</v-icon> 
      </v-btn>
      </template>
      <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
          <CreateUAMUser v-on:close="onClose" />
      </v-card>
    </v-dialog>
</template>
<script>
import CreateUAMUser from '@/components/account_components/CreateUAMUser'
  export default {
      name:'CreateUAMUserModal',
      components:{
          CreateUAMUser
      },
    data () {
      return {
        dialog: false
      }
    },
    methods:{
        onClose(){
            this.dialog=false
            this.$emit('close')
            }
        }

    }
</script>
<style scoped>
.uam{
  border:3px solid;
  border-radius:20px;

}

.v-application .headline{
  color:white;
}
/* .theme--light.v-card {
   background-color: #263238;
} */
</style>
