<template>
    <v-container fluid>
        <v-form ref="uamForm" v-model="valid">
        <v-row>
            <v-col>
            <v-text-field  :dark="$store.getters.getColorPalette().isDark"
                   :color="$store.getters.getColorPalette().inputtextColor"
            :loading="loading"
            v-model="form.name"
            :counter="20"
            :rules="nameRules"
            :label="$store.getters.getTextMap().name"
            required
            ></v-text-field>
            </v-col>
            <v-col>
            <v-text-field  :dark="$store.getters.getColorPalette().isDark"
                   :color="$store.getters.getColorPalette().inputtextColor"
            :loading="loading"
            v-model="form.emp_id"
            :counter="20"
            :label="$store.getters.getTextMap().employee_id"
            required
            ></v-text-field>
            </v-col>
            <v-col>
                <v-text-field 
                    :dark="$store.getters.getColorPalette().isDark" 
                    :loading="loading"
                    v-model="form.email"
                    :counter="40"
                    :rules="[rules.required,rules.email]"
                    :label="$store.getters.getTextMap().email"
                    required
                ></v-text-field>
            </v-col>
            <v-col>
                <v-text-field 
                    :dark="$store.getters.getColorPalette().isDark" 
                    :loading="loading"
                    v-model="form.phone"
                    :counter="10"
                    :rules="[rules.required,rules.noSpace,rules.integ,rules.nonZeroLengthDynamic(10)]"
                    :label="$store.getters.getTextMap().phone"
                    required
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                <v-select
                    v-if="!loading"
                    v-model="form.theme"
                    :items="$store.getters.getThemeList()"
                    :label="$store.getters.getTextMap().theme"
                    dense
                    filled
                    item-text="label"
                    item-value="value"
                    required
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                ></v-select>
            </v-col>
            <v-col>
            <v-text-field  :dark="$store.getters.getColorPalette().isDark"
                 :color="$store.getters.getColorPalette().inputtextColor"
            :loading="loading"
            v-model="form.id"
            :counter="45"
            :rules="idRules"
            :label="$store.getters.getTextMap().user_id"
            required
            ></v-text-field>
            </v-col>
        <v-col>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      
      type="list-item-three-line"
    ></v-skeleton-loader>
          <v-text-field :dark="$store.getters.getColorPalette().isDark"
                   :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.pass"
            :rules="passwordRules"
            :label="$store.getters.getTextMap().new_password"
            prepend-icon="mdi-key"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? $store.getters.getTextMap().text : $store.getters.getTextMap().password"
            @click:append="showPass = !showPass"
            required
            v-if="!loading"
          ></v-text-field>
        </v-col>
    </v-row>
        <v-row>
            <v-col>
                {{info}}
            </v-col>
        </v-row>
        <v-row align="center" justify="space-around">
          <v-col class ="text-center" cols="6" md="6" xl="6" xs="12" sm="12" >
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
              <v-btn v-if="!loading" :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().submitbtnColor" class="px-10"  @click="onSubmit" >{{$store.getters.getTextMap().create}}</v-btn>
              </v-col>
              <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular>
          <v-col class ="text-center" cols="6" md="6" xl="6" xs="12" sm="12" >
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      max-width="300"
      type="text"
    ></v-skeleton-loader>
              <v-btn  v-if="!loading" class="px-10"  @click="onReset" :color="$store.getters.getColorPalette().cancelbtnColor">{{ $store.getters.getTextMap().cancel}}</v-btn>
          </v-col>
    </v-row>
        </v-form>
    </v-container>
</template>
<script>
import axios from 'axios'
import FormRules from  '@/utillities/FormRules'
export default {
    name:'CreateUAMUser',
    data(){
        return {
            showPass:false,
            valid: false,
            info:null,
            showDismissibleAlert: false,
            loading: false,
            form:{
                name:null,
                id:null,
                pass:null,
                email:null,
                phone:null,
                theme:'dark'
            },
            rules:FormRules.rules,
            passwordRules:[
                v=> !!v || 'Required',
                v => (v && v.length >= 8 ) || 'Password must be more than 8 characters',
            ],
            nameRules: [
            v => !!v || 'Name is required',
            v => /\S+/.test(v) || 'Name is required',
            v => (v && v.length <= 20 && v.length > 0) || 'Name must be less than 20 characters',
            ],
            idRules:[
                v => !!v || 'Required',
                v => /^\S+$/.test(v) || 'ID is required',
            v => (v && v.length <= 45 && v.length > 0) || 'Name must be less than 30 characters'
            ],
        }
    },
    methods:{
        onSubmit(){
            this.$refs.uamForm.validate()
            if(this.valid){

                this.loading=true

            let d=Object.assign({}, this.form)

            axios.post(this.$store.state.api+'createUAMUser',d,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }}).then(response=>{
                if(response.data.status==='success'){
                this.loading=false
                this.info="User Added"
                this.$store.dispatch('refreshUAMUsers')
                this.$refs.uamForm.reset()
                this.$emit('close')
                
            }else{
                this.info = response.data.msg;
            
                this.showDismissibleAlert = true;
            
                this.loading=false
            }
            }).catch(err=>{
                this.info=err
                this.loading=false
                this.showDismissibleAlert=true
            })
            }
        },
        onReset(){
            this.$refs.uamForm.reset()
            this.$emit('close')
        }
    }
}
</script>