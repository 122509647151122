<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-row>
            <v-col>
                <v-form ref="orgForm" v-model="valid">
                    <v-card :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
                        <v-card-title  class="headline">{{ title }}</v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <v-text-field 
                                            :dark="$store.getters.getColorPalette().isDark" 
                                            :loading="loading"
                                            v-model="form.pass"
                                            :counter="40"
                                            :rules="[rules.required]"
                                            :label="$store.getters.getTextMap().new_password"
                                            prepend-icon="mdi-key"
                                            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="showPass ? $store.getters.getTextMap().text : $store.getters.getTextMap().password"
                                            @click:append="showPass = !showPass"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field 
                                            :dark="$store.getters.getColorPalette().isDark" 
                                            :loading="loading"
                                            v-model="form.confirm_pass"
                                            :counter="40"
                                            :rules="[rules.required]"
                                            :label="$store.getters.getTextMap().confirm_new_password"
                                            prepend-icon="mdi-key"
                                            :append-icon="showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="showConfirmPass ?$store.getters.getTextMap().text :$store.getters.getTextMap().password"
                                            @click:append="showConfirmPass = !showConfirmPass"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn :color="$store.getters.getColorPalette().submitbtnColor" @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                                <v-btn :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark"  @click="onReset">{{$store.getters.getTextMap().cancel}}</v-btn>
                            </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>        
</template>
<script>
import axios from 'axios';
import InfoAlert from '@/components/InfoAlert'
import FormRules from  '@/utillities/FormRules'
import SecurityUtillity from '@/utillities/SecurityUtillity'
export default {
    name:'ResetUAMPassword',
    props:['user'],
    components:{
        InfoAlert
    },
    data(){
        return {
          form:{
            pass:null
          },
          api:'resetUAMPassword',
          title:'Reset UAM Password',
          loading:false,
          showDismissibleAlert:false,
          valid:false,
          showPass:false,
          showConfirmPass:false,
          info:'',
          rules:FormRules.rules,
        }
    },
    mounted(){
      if(this.user){
        this.api='resetUAMPassword'
        this.title='Reset UAM Password'
        this.form = Object.assign({},this.user)
        this.form.accessList=SecurityUtillity.getAccessListByUserLevel(this.user.user_level)
      }
    },
    computed:{
      accessOptions(){
            let op=[]
            for (let i of SecurityUtillity.accessOptions){
                op.push(i)
            }
            return op
        },
        userAccess(){
          if(this.user && this.user.user_level)
            return SecurityUtillity.getLabels(this.user.user_level)
            return []
        },
    },
    methods:{
        onSubmit(){
            this.$refs.orgForm.validate()
            if(this.valid){
              this.loading = true;
              axios.post(this.$store.state.api + this.api, this.form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
              .then(response => {
              if (response.data.status == "success") {
                this.$store.dispatch("refreshUAMUsers")
                this.info = "Password Reset Successful";
                this.showDismissibleAlert = true;
                this.loading = false;
                this.onReset()
                this.$emit('close')
              } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
              }
                this.loading = false;
              })
              .catch(error => {
                this.onReset()
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
              });
            }
        },
        onReset(){
            this.$emit('close')   
        }
    }
}
</script>
<style scoped>
/* .v-application .headline{
  color:#fff
}
.v-sheet.v-card{
  background-color: #263238
} */
</style>