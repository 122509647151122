<template>
   
        <v-container fluid>
            <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
            <v-row v-if="loading">
                <v-col align="center">
                    <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular>
                </v-col>
            </v-row>
            
            <v-row v-if="!loading">
                <v-col self-align="center">
                    <v-icon
        small
      >
        mdi-clock-outline
      </v-icon><span class="text-caption">{{$store.getters.getTextMap().creation_time}}:</span> {{creationTime}} Hrs
                </v-col>
                <v-col>
                    <EditUserModal :user="user" />
                </v-col>
                <v-col v-if="$store.state.user && $store.getters.getAccessByLevels(['uam','betaaccess'])">
                    <ResetUAMPasswordModal :user="user" />
                </v-col>
                <v-col>
                    <span v-if="$store.state.user && $store.getters.getAccessByLevels(['uam'])">
        <v-skeleton-loader
                        v-if="loading"
                    class="mx-auto"
                    type="text"
                    ></v-skeleton-loader>
       <DeleteConfirmation  v-on:confirm="deleteUser()"  title="Delete Confirmation"  description="Are you sure you want to delete this user?">  
     
      <v-btn text small outlined :dark="$store.getters.getColorPalette().isDark" :background-color="$store.getters.getColorPalette().background1ColorCode" :color="$store.getters.getColorPalette().btnborderColorCode" >
        {{$store.getters.getTextMap().delete_user}}
<v-icon right :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor">mdi-delete</v-icon>
        </v-btn>
      </DeleteConfirmation>
     
    </span>
    
                </v-col>
            </v-row>
            <br>
            <v-divider></v-divider>
           
            <v-divider></v-divider>
            <v-row>
                <v-col cols="2" align-self="center" align="center"><strong>{{$store.getters.getTextMap().permissions}}:</strong></v-col>
                <v-col align="left">
                    
                    <v-chip
      class="ma-2"
      
      color="teal"
      text-color="white" v-for="param in userAccess"
        :key="param">
        {{param}}
                    </v-chip>
                </v-col>
            </v-row>
            
        </v-container>
    
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import SecurityUtillity from '@/utillities/SecurityUtillity'
import ResetUAMPasswordModal from '@/components/modals/accounts/ResetUAMPasswordModal'
import EditUserModal from '@/components/modals/accounts/EditUserModal'
export default {
    name:'UAMUser',
    props:['user'],
    components:{
    InfoAlert,
    DeleteConfirmation,
    EditUserModal,
    ResetUAMPasswordModal
},
    
    data(){
        return {
            showDialog:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            valid:false,
            permissionEditView:false,
            accessList:[],
            accessOp:SecurityUtillity.accessOp,
            // [
            //     {label:"Enture Device Access", value:"device"},
            //     {label:"Report Access", value:"report"},
            //     {label:"Control Node Access", value:"node"},
            //     {label:"Scheduling Access", value:"scheduling"},
            //     {label:"Ebill Access", value:"ebill"},
            //     {label:"DataSource Access", value:"datasource"},
            //     {label:"Engineer's Access", value:"engineeraccess"},
            //     {label:"Network Access", value:"network"},
            //     {label:"User Access Management", value:"uam"},
            //     {label:"Hub Access", value:"hub"},
            //     {label:"Device Parameter Access", value:"parameter"},
            //     {label:"Monitoring Node Access", value:"snode"},
            //     {label:"Log Access", value:"logs"},
            //     {label:"Beta Access", value:"betaaccess"},
            //     {label:"write Access", value:"write"},
            // ],
            objectRules:[
                v => !!v || 'Required',
              ],
              listRules:[
                v=> !!v || 'Required',
                v => (v && v.length>0) || 'You have to select something',
            ],
        }
    },
    computed:{
        creationTime(){
            if(this.user && this.user.creation_time)
            return moment(this.user.creation_time.toString(),'X').format("DD-MM-YYYY - HH:mm")
            else
            return 'unknown'
        },
        userAccess(){
            return SecurityUtillity.getLabels(this.user.user_level) //SecurityUtillity.getAccessListByUserLevel(this.user.user_level).map(x=>{return this.accessOp.find(y=>y.value==x).label})
        },
    },
    methods: {
        giveAccessToUser(){
            this.$refs.userForm.validate()
            if(this.valid){
                this.loading=true
                let payload={
                    type:'uam',
                    accessList:this.accessList,
                    userid:this.user.userid
                }
                axios.post(this.$store.state.api+'updateUserLevel',payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }}).then(response=>{
                if(response.data.status==='success'){
                    this.$store.dispatch('refreshUAMUsers')
                this.loading=false
                this.info="User Permissions Updated"
                this.permissionEditView=false
                
                
            }else{
                this.info = response.data.msg;
            
                this.showDismissibleAlert = true;
            
                this.loading=false
            }
            }).catch(err=>{
                this.info=err
                this.loading=false
                this.showDismissibleAlert=true
            })
            }

        },
        deleteUser(){
            this.loading=true
            axios.post(this.$store.state.api+'deleteUAMUser',this.user,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }}).then(response=>{
                if(response.data.status==='success'){
                this.loading=false
                this.info="User Deleted"
                this.$store.dispatch('refreshUAMUsers')
                
                
            }else{
                this.info = response.data.msg;
            
                this.showDismissibleAlert = true;
            
                this.loading=false
            }
            }).catch(err=>{
                this.info=err
                this.loading=false
                this.showDismissibleAlert=true
            })
        },
        cancel(){
          this.showDialog=false
        },
        confirm(){

          this.showDialog=false
        }
    },
}
</script>