<template>
  <v-container>
      <v-card  :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" :dark="$store.getters.getColorPalette().isDark" >
          <v-card-title>
              <v-text-field 
              v-model="search"
              append-icon="mdi-magnify"
              :label="$store.getters.getTextMap().search"
              single-line
              hide-details
              :dark="$store.getters.getColorPalette().isDark" 
              >
              </v-text-field>
          </v-card-title>
          <v-data-table
          :headers="headers"
          :items="users"
          item-key="email"
          :search="search"
          :single-expand="true"
          show-expand
          :expanded.sync="expanded"
          :loading="loading"
          loading-text="Loading... Please wait"
         :dark="$store.getters.getColorPalette().isDark" 
          >

          <template v-slot:top>
              <v-toolbar flat>
                  <v-toolbar-title>{{$store.getters.getTextMap().root_user_management}}</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <!-- <CreateRootUserModal v-if="$store.state.user && $store.getters.getAccessByLevels(['uam'])" /> -->
              </v-toolbar>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                 
                  <RootUser :user="item" v-on:refreshUsers="getRootUsers" />
                  </td>
          </template>
          </v-data-table>
      </v-card>
  </v-container>
</template>

<script>
// import CreateRootUserModal from '@/components/modals/CreateRootUserModal'
import RootUser from '@/components/items/RootUser'
import moment from 'moment'
import axios from 'axios'

export default {
    name:'RootTable',
    components:{
        // CreateRootUserModal,
        RootUser
    },
    mounted(){
        this.getRootUsers()
    },
    data(){
        return {
            search:'',
            loading:false,
            expanded: [],
            users:[],
            headers: [
          {
            text: 'ID',
            align: 'start',
            filterable: false,
            value: 'email',
            class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
          },
          { text: 'Name', value: 'name',class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Account Type', value: 'account_type', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          
          
        ],
        }
    },
    computed:{
        
    },
    methods: {
        getTime(timestamp){
            return moment(timestamp.toString(),'x').format("YYYY-MM-DD HH:mm")
        },
        getRootUsers(){
            this.loading=true
            axios.get(this.$store.state.api+'rootUsers',{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }}).then(response=>{
                if(response.data.status==='success'){
                    
                    this.users=response.data.users
                    //this.onReset()
                    
                        this.loading=false
                        //this.dialog=false
                        //this.$emit('close')
                }else{
                    console.error(this.response.data.msg)
                }
                })
                .catch(err=>{
                    console.error(err)
                this.loading=false});
        }
    },
}
</script>

<style scoped>

.v-card__title{
  background-color:#BDBDBD00;
}
.theme--light.v-toolbar.v-sheet{
  background-color: #B2DFDB00;
}
.theme--light.v-data-table{
   background-color: #B2DFDB00;
}
</style>
