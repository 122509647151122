<template>
    <div class=" mx-5 my-5">
    <!-- <h1 class="text-subtitle-2 text-capitalize grey--text upper"> User</h1> -->
    <v-container fluid>
        <v-row align="end">
            <v-col cols="auto" class="d-flex">
                <UserAccessLogModal/>
            </v-col>
            <!-- <v-col cols="auto" class="d-flex">
                <CreateUAMUserForSSOModal v-if="$store.state.user && $store.getters.getAccessByLevels(['uam','alphaaccess'])" />
            </v-col> -->
        </v-row>
        <v-divider></v-divider>
        <v-row  v-if="$store.state.user && $store.getters.getAccessByLevels(['uam'])">
            <v-col cols="12">
                <UAMPage />
            </v-col>
        </v-row>
        
    </v-container>
    </div>
</template>

<script>
import UAMPage from '@/pages/UAMPage'
import UserAccessLogModal from '@/components/modals/UserAccessLogModal'
// import CreateUAMUserForSSOModal from '@/components/modals/sso/CreateUAMUserForSSOModal'

export default {
    name:'User',
    data(){
        return {}
    },
    components:{
        UAMPage,
        UserAccessLogModal,
        // CreateUAMUserForSSOModal
        
    },
    mounted() {
         if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }
    },
}
</script>
<style scoped>
.container{
    margin-top: -1%;
}
</style>