<template>
<v-container fluid>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-form
    ref="logForm"
    v-model="valid"
    
  >
    <v-row>
        <v-col>
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-menu
                
        v-if="!loading"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
        
            v-model="form.date"
            label="Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.date"
          @input="menu = false"
        ></v-date-picker>
      </v-menu>
            </v-col>


<v-col>
    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    <v-menu
        v-if="!loading"
        ref="menufrom"
        v-model="menu3"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="fromTime"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="fromTime"
            label="From"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu3"
          v-model="fromTime"
          full-width
          format="24hr"
          @click:minute="$refs.menufrom.save(fromTime)"
        ></v-time-picker>
      </v-menu>
            </v-col>

            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
<v-menu
v-if="!loading"
        ref="menuto"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="toTime"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="toTime"
            label="To"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu2"
          v-model="toTime"
          full-width
          format="24hr"
          @click:minute="$refs.menuto.save(toTime)"
        ></v-time-picker>
      </v-menu>
            </v-col>
            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-btn
                v-if="!loading"
                ref="submitBtn"
            :color="$store.getters.getColorPalette().submitbtnColor"
            outlined
            @click="onSubmit"
          >
            Fetch Logs
          </v-btn>
          
            </v-col>
            
    </v-row>
    </v-form>
    <v-row>
                <v-col cols="12">
                    <v-card>
    <v-card-title>
      
    </v-card-title>
                    <v-data-table
      :headers="headers"
      :items="logs"
      item-key="timestamp"
      :search="search"
      :single-expand="true"
      
      :loading="loading"
    loading-text="Loading... Please wait"
    >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>User Logs</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
        <v-spacer></v-spacer>
        <span>
            <v-icon
        
        @click="download"
      >
        mdi-download
      </v-icon>
        </span>
            </v-toolbar>
          </template>
                    </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
            
<a ref="csvDownload" hidden></a>
</v-container>
    
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'UserAccessLog',
    components:{
        InfoAlert
    },
    mounted(){
        this.form.date=moment().format('YYYY-MM-DD')
        this.toTime='23:59'
        this.fromTime='00:00'
        //let btn=this.$refs.submitBtn
        //btn.click()
        this.onSubmit()
    },
    data(){
        return {
            search:'',
             valid:false,
                menu:false,
                menu2:false,
                menu3:false,
            graphData:[],
            toTime:null,
            fromTime:null,
            showDismissibleAlert:false,
            loading:false,
            info:'',
            expanded: [],
            logs:[],
            form:{
                date:null
            },
            headers: [
          {
            text: 'Time',
            align: 'start',
            filterable: false,
            value: 'time',
          },
          { text: 'Log', value: 'log' },
          { text:'Logged By', value:'userid'},
          
          { text: 'Type', value: 'user_type' },
            ],
        
            objectRules:[
                v => !!v || 'Required',
            ],
        }
    },
    computed:{
        fromTimeStamp(){
        if(this.form.date){
          
            let dt=this.form.date.toString()
            if(this.fromTime){
              dt+=' '+this.fromTime.toString()
              return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
            }
            return Number(moment(dt,'YYYY-MM-DD').format("X"));
          
        }else{
          return null
        }
      },
      toTimeStamp(){
        if(this.form.date){
          
            let dt=this.form.date.toString()
            if(this.toTime){
              dt+=' '+this.toTime.toString()
              //return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
            }else{
                dt+=' 23:59'
            }
            //return Number(moment(dt,'YYYY-MM-DD').format("X"));
            return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
          
        }else{
          return null
        }
      },
    },
    methods: {
        download(){
            let header=['time','log','userid','user_type']
    //this.test=response.data.data
    let csvHeader=['Time','Log','Logged By','Type of User'].join(',')+"\n"
            let csv=csvHeader
            
            for(let i of this.logs){
              //this.test=i
              for(let k of header){
                //console.log(k)
                if(i[k]){
                csv+='"'+i[k]+'",'
                }else{
                  csv+='-,'
                }
              }
              csv+="\n"
            }
            
            //csv+=body.map(e => e.join(",")).join("\n");
            let csvData = new Blob([csv], { type: 'text/csv' });  
            let csvUrl = URL.createObjectURL(csvData);
            //let hiddenElement = document.createElement('a');
            let hiddenElement = this.$refs.csvDownload;
            hiddenElement.href = csvUrl;
            hiddenElement.target = '_blank';
            hiddenElement.download = 'User_access_log_'+moment().format('YYYYMMDDHHmm') + '.csv';
            hiddenElement.click();
        },
        onSubmit(){
            this.$refs.logForm.validate()
            if(this.valid){
              this.loading=true
              //let fromTimeStamp=this.fromTimeStamp
              //let toTimeStamp=this.toTimeStamp
              let payload={
      from_time:this.fromTimeStamp,
      to_time:this.toTimeStamp
    }
    
    //console.log(fromTimeStamp)
    axios.post(this.$store.state.api+'userAccessLog',payload,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      //console.log("Param data received")
      //console.log(response.data.monthlyData)
      //context.state.monthlyData=response.data.monthlyData
//console.log(response.data.data)
//console.log('something')


if(response.data.status==='success'){
let data=[]
for (let i of response.data.data){
  i['time']=moment(i['time']).format("dddd, MMMM Do YYYY, h:mm:ss a")
    data.push(i)
  
}
this.logs=data
      //console.log(response.data.data)
      
      if(this.logs.length==0){
          this.info = "No Log in for selected timeframe";
            this.showDismissibleAlert = true;
      }
    }else{
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      //this.loading=false
    }
      this.loading=false

    })
    .catch(err=>{
    this.showDismissibleAlert=true
            this.info=err
            this.loading=false
            })

    
    
    
    
            }
        }
    
    },
}
</script>