<template>
  <div class="text-right">
    <v-btn
      class="indigo lighten-3"
      text
      v-if="!loading"
      @click="dialog = !dialog"
      small
      outlined
    >
    {{  $store.getters.getTextMap().user_access_log}}

</v-btn>
  

    

    

    
    
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
    <v-toolbar
        :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().tab"
          
        >
          <v-btn
            icon
          :dark="$store.getters.getColorPalette().isDark"
            small
            @click="dialog = false"
            
            
          >
          
          
            <v-icon small>mdi-close</v-icon>
          </v-btn>
          

          
          <v-toolbar-title>{{$store.state.organisation?$store.state.organisation.name:''}} -   {{  $store.getters.getTextMap().user_access_log}} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
            :dark="$store.getters.getColorPalette().isDark"
              text
              @click="dialog = false"
              
            >
            {{  $store.getters.getTextMap().close }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <UserAccessLog/>
        </v-card>
        </v-dialog>
  </div>
</template>
<script>

//import axios from 'axios'
import UserAccessLog from '@/components/panels/UserAccessLog'
export default {
    name:'UserAccessLogModal',
    components:{
       UserAccessLog,
       
       
       
    },
    data(){
        return {
            loading:false,
            dialog:false,
        }
      },
    computed:{
       
    },
    methods:{
        
      toggleForm (){
            this.showForm = !this.showForm
        }
    
    },
    
        
    
}
</script>