<template>
   
        <v-container fluid>
            <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
            <v-row v-if="loading">
                <v-col align="center">
                    <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular>
                </v-col>
            </v-row>
            
            <v-row v-if="!loading">
                <v-col self-align="center">
                    <v-icon
        small
      >
        mdi-clock-outline
      </v-icon><span class="text-caption"> {{$store.getters.getTextMap().creation_time}}:</span> {{creationTime}} 
                </v-col>
                <v-col align="right">
                              <EditRootUserModal :user="user" />
                  </v-col>
                <v-col align="right">
                    <span v-if="$store.state.user && $store.getters.getAccessByLevels(['uam'])">
        <v-skeleton-loader
                        v-if="loading"
                    class="mx-auto"
                    type="text"
                    ></v-skeleton-loader>
      <v-icon
        small
        color="grey"
        @click="permissionEditView=!permissionEditView"
      >
        mdi-pencil
      </v-icon>
        <DeleteConfirmation  :confirm="deleteUser"  title="Delete Confirmation"  description="Are you sure you want to delete this user?"> 
      <v-icon
      v-if="$store.state.user && $store.getters.getAccessByLevels(['uam'])"
        small
     :color="$store.getters.getColorPalette().deletebtnColor"
      >
        mdi-delete
      </v-icon>
      </DeleteConfirmation>
     
    </span>
    
                </v-col>
            </v-row>
            <br>
            <v-divider></v-divider>
            <v-form 
            ref="userForm"
            v-model="valid"
            >
            <v-row v-if="permissionEditView">
                <v-col>
                    <v-skeleton-loader
                        v-if="loading"
                    class="mx-auto"
                    type="text"
                    ></v-skeleton-loader>
                    <v-select
    v-if="!loading"
          v-model="accessList"
          :items="accessOp"
          item-text="label"
          item-value="value"          
          :label="$store.getters.getTextMap().permissions"
         :rules="listRules"
         required
          multiple
          small-chips
          clearable
        ></v-select>
                </v-col>
                <v-col align-self="center" cols="2">
                    <v-skeleton-loader
                        v-if="loading"
                    class="mx-auto"
                    type="text"
                    ></v-skeleton-loader>
                    <v-btn color="blue" :dark="$store.getters.getColorPalette().isDark" rounded small @click="giveAccessToUser">{{$store.getters.getTextMap().grant_permission}}
</v-btn>
                </v-col>
            </v-row>
            </v-form>
            <v-divider></v-divider>
            <v-row>
                <v-col cols="2" align-self="center" align="center"><strong>Permissions:</strong></v-col>
                <v-col align="left">
                    
                    <v-chip
      class="ma-2"
      
      color="teal"
      text-color="white" v-for="param in userAccess"
        :key="param">
        {{param}}
                    </v-chip>
                </v-col>
            </v-row>
            
        </v-container>
    
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import SecurityUtillity from '@/utillities/SecurityUtillity'
import EditRootUserModal from '@/components/modals/accounts/EditRootUserModal'
export default {
    name:'RootUser',
    props:['user'],
    components:{
        InfoAlert,
        DeleteConfirmation,
        EditRootUserModal
    },
    
    data(){
        return {
            showDialog:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            valid:false,
            permissionEditView:false,
            accessList:[],
            accessOp:SecurityUtillity.accessOp,
            // [
            //     {label:"Enture Device Access", value:"device"},
            //     {label:"Report Access", value:"report"},
            //     {label:"Control Node Access", value:"node"},
            //     {label:"Scheduling Access", value:"scheduling"},
            //     {label:"Ebill Access", value:"ebill"},
            //     {label:"DataSource Access", value:"datasource"},
            //     {label:"Engineer's Access", value:"engineeraccess"},
            //     {label:"User Access Management", value:"uam"},
            //     {label:"Hub Access", value:"hub"},
            //     {label:"Device Parameter Access", value:"parameter"},
            //     {label:"Monitoring Node Access", value:"snode"},
            //     {label:"Power Node Access", value:"pnode"},
            //     {label:"Beta Access", value:"betaaccess"},
            //     {label:"Log Access", value:'logs'}
            // ],
            objectRules:[
                v => !!v || 'Required',
              ],
              listRules:[
                v=> !!v || 'Required',
                v => (v && v.length>0) || 'You have to select something',
            ],
        }
    },
    computed:{
        creationTime(){
            if(this.user && this.user.creation_time)
            return moment(this.user.creation_time.toString(),'X').format("DD-MM-YYYY - HH:mm")
            else
            return 'unknown'
        },
        accessOptions(){
            let op=[]
            for (let i of Object.keys(SecurityUtillity.securityConfig)){
                op.push({
                    label:i, value:i
                })

            }
            return op
        },
        userAccess(){
            return SecurityUtillity.getLabels(this.user.user_level)
            
        },
    },
    methods: {
        giveAccessToUser(){
            this.$refs.userForm.validate()
            if(this.valid){
                this.loading=true
                let payload={
                    type:'root',
                    accessList:this.accessList,
                    userid:this.user.email
                }
                axios.post(this.$store.state.api+'updateUserLevel',payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }}).then(response=>{
                if(response.data.status==='success'){
                    //refresh parent component
                    this.$emit('refreshUsers')
                this.loading=false
                this.info="User Permissions Updated"
                this.permissionEditView=false
                
                
            }else{
                this.info = response.data.msg;
            
                this.showDismissibleAlert = true;
            
                this.loading=false
            }
            }).catch(err=>{
                this.info=err
                this.loading=false
                this.showDismissibleAlert=true
            })
            }

        },
        deleteUser(){
            this.loading=true
            axios.post(this.$store.state.api+'deleteRootUser',this.user,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }}).then(response=>{
                if(response.data.status==='success'){
                this.loading=false
                this.info=" Root User Deleted"
                this.$store.dispatch('refreshRootUsers')
                
                
            }else{
                this.info = response.data.msg;
            
                this.showDismissibleAlert = true;
            
                this.loading=false
            }
            }).catch(err=>{
                this.info=err
                this.loading=false
                this.showDismissibleAlert=true
            })
        },
                cancel(){
          this.showDialog=false
        },
        confirm(){

          this.showDialog=false
        }
    },
}
</script>