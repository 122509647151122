<template>
    <div class="text-center"  :dark="$store.getters.getColorPalette().isDark" >
        <!-- class="devc"  -->
        <v-btn text small outlined :dark="$store.getters.getColorPalette().isDark" :background-color="$store.getters.getColorPalette().background1ColorCode" :color="$store.getters.getColorPalette().btnborderColorCode" @click="dialog = !dialog">
            Reset Password <v-icon right :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode">mdi-key</v-icon>
        </v-btn>
        <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                    <v-toolbar-title>{{$store.getters.getTextMap().update_user}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="dialog = false">{{$store.getters.getTextMap().close}}</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <ResetUAMPassword v-on:close="dialog=false" :user="user" />
            </v-card>
      </v-dialog>
    </div>
</template>
<script>
  import ResetUAMPassword from '@/components/account_components/ResetUAMPassword'
  export default {
      name:'ResetUAMPasswordModal',
      props:['user'],
      components:{
        ResetUAMPassword  
      },
      data(){
          return {
              dialog:false
          }
      },
      methods:{
          
      }
  }
  </script>
  <style scoped>
  .devc{
     border:3px solid;
    border-radius:20px;
  
  }
  
  </style>